import React from 'react'
import { Checkout } from '../../../components/common'
import { Row, Column, BackgroundImage, ImageHeading, Paragraph, } from '../../../pageStyles'

import {
    Container,
    Heading,
    TextContainer,
} from './styled'

const About = ({ img }) => (
    <Container>
        <Row>
            <Column>
                <BackgroundImage src={img}>
                    <ImageHeading>NorthFPV Club</ImageHeading>
                </BackgroundImage>
            </Column>
            <Column>
                <TextContainer>
                    <Heading>
                        Il gruppo
                    </Heading>
                    <Paragraph>
                        NorthFPV è una community FPV Italiana, creata per insegnare e condividere le emozioni del volo in FPV. Tramite il Club organizziamo eventi per volare insieme, decidiamo la direzione del nostro canale YouTube.
                    </Paragraph>
                    <Checkout cssClass="btn-club" buttonLabel="Entra Ora!" cancelPath="club" price="price_1Ho7r7AafUY8FLlUjbI47FVV" />
                </TextContainer>
            </Column>
        </Row>
    </Container>
)

export default About;
import styled from 'styled-components';
import { colors, fonts, media } from '../../../utils';

export const Title = styled(fonts.Billboard)`
    color: ${colors.white};
    font-size: 82px;
    font-weight: 700;
    text-transform: uppercase;
`;

export const Paragraph = styled(fonts.SubHeading)`
    color: ${colors.white};
    margin: 2rem 0;
`;
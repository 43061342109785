import styled from 'styled-components';
import { colors, fonts, media } from '../../../utils';

export const Container = styled.div`
    max-width: 1250px;
    margin: 4rem auto;
`;

export const Heading = styled(fonts.Heading)`
    text-transform: uppercase;
    margin: 0 0 20px 0;
    font-weight: 800;
    font-size: 36px;
    color: ${colors.black};
    position: relative;
    margin-bottom: 15px;
    padding-bottom: 15px;

    &:after {
        position: absolute;
        content: "";
        background: linear-gradient(to right, #eda550 0%, #eda550 100%);
        height: 4px;
        width: 60px;
        bottom: 0;
        left: 0;
    }
`;

export const TextContainer = styled.div`
    margin: 0 auto;
    padding: 0 4vw;
`;
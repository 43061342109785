import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { Landing } from '../../components/common'

import { Hero } from '../../components/common/Hero'

import {
  About,
  Features,
} from '../../components/club'

// Styles
import '../../styles/app.css'

const Index = ({ data, location, pageContext }) => {
  console.log(process.env)
    return (
        <>
            <Helmet>
            <link
                rel="stylesheet"
                href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"
                integrity="sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk"
                crossOrigin="anonymous"
                />
            </Helmet>
            <Landing>
                <Hero title="NorthFPV Club" subtitle="Il club più esclusivo nel mondo FPV, è aperto per nuove iscrizioni" image={data.hero.childImageSharp.fixed.srcWebp} ctaLabel="Entra nel club"/>
                <Features/>
                <About img={data.about.childImageSharp.fixed.srcWebp}/>
            </Landing>
        </>
    )
}

export default Index;


export const pageQuery = graphql`
  query {
    about: file(relativePath: {eq: "northfpv_1.jpg"}) {
        childImageSharp {
          fixed(width: 1280, height: 850) {
              srcWebp
          }
        }
      }
      hero: file(relativePath: {eq: "northfpv_2.jpg"}) {
        childImageSharp {
          fixed(width: 1280, height: 850) {
              srcWebp
          }
        }
      }
}
`;
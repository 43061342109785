import React from 'react'

const Features = ({ title, subtitle, ctaHref, ctaLabel, image }) => (
    <>
        <div id="features" class="text-center">
            <div class="club-container">
                <div class="col-md-10 col-md-offset-1 section-title">
                    <h2>Vantaggi del Club</h2>
                </div>
                <div class="row">
                    <div class="col-xs-6 col-md-3">
                        <i class="fa fa-gift"></i>
                        <h3>Regalo di benvenuto</h3>
                        <p>L'iscrizione al Club di NorthFPV comprende un regalo di benvenuto esclusivo. Non appena entrerai a far pare del club, riceverai <b>in regalo la T-Shirt ufficiale</b> di NorthFPV e un <b>set di stickers</b> che potrai appiccicare ovunque.</p>
                    </div>
                    <div class="col-xs-6 col-md-3">
                        <i class="fa fa-comments-o"></i>
                        <h3>Alpha Tester</h3>
                        <p>NorthFPV è un progetto in continua evoluzione e abbiamo diverse occasioni in cui testare: <b>nuovi tipo di video</b>, prove interne, feature aggiuntive. In ogni caso questi contenuti saranno resi disponibili in via esclusiva soltanto ai membri del club.</p>
                    </div>
                    <div class="col-xs-6 col-md-3">
                        <i class="fa fa-group"></i>
                        <h3>Gruppo privato</h3>
                        <p>Per ringraziarti del supporto avrai accesso al <b>gruppo esclusivo</b> su Telegram. Tramite la chat potrai socializzare con gli altri membri del Club e parlare di qualsiasi argomento. <b>Nichoroberts</b> e <b>Luca FPV</b> partecipano alla chat, interagendo con gli utenti.</p>
                    </div>
                    <div class="col-xs-6 col-md-3">
                        <i class="fa fa-bullhorn"></i>
                        <h3>Offerte in anteprima</h3>
                        <p>Tramite NorthFPV Club riceverai sconti sulla nostra linea di abbigliamento otterrai l'<b>accesso in anteprima</b> alle nuove linee di prodotti, così da poterli comprare prima di tutti! Inoltre, riceverai degli sconti unici da poter utilizzare sui migliori siti di droni FPV.</p>
                    </div>
                </div>
            </div>
        </div>
    </>
)

export default Features;
import React from 'react'
import { Row, BackgroundImage } from '../../../pageStyles'
import { Title, Paragraph } from './styled'
import { Checkout } from '../index'

export const Hero = ({ title, subtitle, ctaHref, ctaLabel, image }) => (
    <>
        <header id="header">
            <BackgroundImage src={image}>
                <Row>
                    <div class="col-md-8 col-md-offset-2 intro-text">
                        <Title>{title}</Title>
                        <Paragraph>{subtitle}</Paragraph>
                        { ctaHref ? 
                            <a href={ctaHref} class="btn-club page-scroll">{ctaLabel}</a> : 
                            <Checkout cssClass="btn-club" buttonLabel={ctaLabel} price="price_1Ho7r7AafUY8FLlUjbI47FVV" cancelPath="club" /> 
                        }
                    </div>
                </Row>
            </BackgroundImage>
        </header>
    </>
)
